<template>
  <div>
    <el-dialog
      width="460px"
      center
      :title="payTitle"
      :visible.sync="visible"
      @closed="onClose"
    >
      <div class="payInner">
        <ul class="choosePayStyle">
          <li
            v-for="item in PayStyleOpt"
            @click="choosePayStyle(item)"
            :key="item.key"
            :class="{ active: chooseStyle == item.key }"
          >
            <i
              class="iconfont"
              :class="{
                aliPayColor: item.key == 'alipay',
                weChatColor: item.key == 'wechat',
                moneyBagColor: item.key == 'moneyBag',
              }"
              v-html="item.icon"
            >
            </i>
            <span>{{ item.label }}</span>
          </li>
        </ul>
        <!-- 选择支付宝/微信 -->
        <div class="code" v-if="chooseStyle != 'moneyBag'" key="NOmoneyBag">
          <div ref="qrcode" id="qrcode"></div>
        </div>

        <!-- 选择钱包 -->
        <div class="moneyBag" key="moneyBag" v-else>
          <ul class="baseUl">
            <li>
              <span> 当前余额（元）：</span>
              <span class="price"> 1000 </span>
            </li>
            <li>
              <span> 需支付（元）：</span>
              <span class="price"> 198 </span>
            </li>
          </ul>
          <div class="btnWrap">
            <el-button type="primary">支付</el-button>
          </div>
        </div>
        <!-- 提示 -->
        <div class="tips">支付完成之时起5分钟内生效</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import { queryOrder,paymentOrder } from "@/api/GoodsService";
export default {
  name: "",

  data() {
    return {
      // 弹框是否显示
      visible: false,
      chooseStyle: "wechat",
      orderNo:null,
      payTitle:'支付',
      orderInfo: null,
      orderStatus: "10",
      checkStatusId: -1,
      paymentRequest: null,
      PayStyleOpt: [
        {
          key: "alipay",
          label: "支付宝支付",
          icon: "&#xe634;",
        },
        {
          key: "wechat",
          label: "微信支付",
          icon: "&#xe633;",
        },
        // {
        //   key: "moneyBag",
        //   label: "余额支付",
        //   icon: "&#xe632; ",
        // },
      ],
    };
  },
  created() {
    // this.visible = true
    this.init();
  },
  beforeDestroy() {
    console.log('beforeDestroy');
    let that = this;
    if (that.checkStatusId != -1) {
      clearInterval(that.checkStatusId);
      that.checkStatusId = -1;
    }
  },
  methods: {
    init() {
      console.log("init pay..");
    },
    choosePayStyle(item) {
      this.chooseStyle = item.key;
    },
    // 关闭弹框时触发的事件
    onClose() {
      document.getElementById("qrcode").innerHTML = "";
      this.$emit("close", this.orderStatus == "10");
    },
    checkOrderStatus() {
      let that = this;
      if (that.checkStatusId != -1) {
        clearInterval(that.checkStatusId);
      }
      let checkStatus = () => {
        queryOrder(that.orderInfo.orderNo).then(({ data }) => {
          if (data.paymentStatus == "10") {
            //已支付
            clearInterval(that.checkStatusId);
            that.orderStatus = "10";
            that.visible = false;
          }
        });
      };

      that.checkStatusId = setInterval(checkStatus, 5000);
    },
    showDialog(orderInfo,price) {
      this.orderInfo = orderInfo;
      this.payTitle=`支付${price}元`;
      this.chooseStyle = 'wechat';
      this.paymentRequest = orderInfo;
      this.visible = true;
      if (this.chooseStyle == "wechat") {
        setTimeout(() => {
          console.log("hello..");
          var qrcode = new QRCode("qrcode", {
            text: this.paymentRequest.payResponse.code_url, // 需要转换为二维码的内容
            width: 100,
            height: 100,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H,
          });
        }, 400);

        this.checkOrderStatus();
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.payInner {
  color: rgba(0, 18, 22, 0.65);
  font-size: 16px;
  padding-bottom: 16px;
  /deep/ .el-dialog__header .el-dialog__title {
    font-size: 24px;
  }
  .choosePayStyle {
    display: flex;
    justify-content: center;
    align-items: center;
    .iconfont {
      font-size: 32px;
      margin-right: 4px;
      line-height: 1em;
    }
    li {
      display: flex;
      margin: 0 8px;
      align-items: center;
      border: solid 1px rgba(0, 18, 22, 0.2);
      border-radius: 2px;
      padding: 2px 8px;
    }
    li.active {
      color: #007DFF;
      border-color: #007DFF;
    }
  }
  .price {
    color: rgba(0, 18, 22, 0.85);
    font-weight: bold;
    font-size: 20px;
  }
  .moneyBag {
    padding: 48px 48px 0;
  }
  .btnWrap {
    margin: 32px 0 16px;
    .el-button--primary {
      width: 100%;
    }
  }
  .tips {
    text-align: center;
  }
  .code {
    display: flex;
    justify-content: center;
    margin: 36px 0 24px;
    img {
      width: 144px;
    }
  }
}
</style>
