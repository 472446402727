import request from '@/utils/request'



export function queryGoods(goodsId) {
    return request(
        'get',
        '/v1',
       `/shop/goods/info/${goodsId}`
    );
}


export function applyOrder(params) {
    return request(
        'post',
        '/v1',
       `/shop/order`,
       params
    );
}


export function paymentOrder(orderNo,params) {
    return request(
        'post',
        '/v1',
       `/shop/order/payment/${orderNo}`,
       params
    );
}

export function queryOrder(orderNo) {
    return request(
        'get',
        '/v1',
       `/shop/order/${orderNo}`
    );
}