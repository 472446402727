<template>
  <div>
    <el-dialog
      width="70%"
      center
      title="授权协议"
      :visible.sync="visible"
      @closed="onClose"
    >
     <div class="agreement">
        <div class="topTittle">《知链电子书授权协议》</div>
        <div class="time">  最近更新日期：2019年1月23日   生效日期：2019年1月30日 </div>
        <div class="desc">
            <p>
              本协议是您（包括自然人、法人或其他组织，以下也称"服务使用人"）与知链科技有限公司（以下简称”知链”）之间关于知链电子书挂卖合约提供各项服务的协议。
            </p>
            <p>
              <strong>
                一、知链电子书授权使用范围
              </strong>
            </p>
            <p>
              1.1  获取授权后，用户请自觉遵守相关使用条例，请勿侵权使用。 <br>

1.2  您应正当使用知链的各项服务，并承担一定的意外风险。在使用知链服务过程中，您不得从事任何影响网络正常运作的行为，包括借助装置、软件或例行程序进行干预等。知链在任何情况下不对因服务使用人不正当使用、有意或恶意利用服务的缺陷或其他特殊的、意外的原因（或行为）造成的非直接或间接的损失承担赔偿责任。<br>

1.3  由于知链的各项服务涉及到互联网服务、本地操作环境、网络各环节等因素的影响，因此服务可能因计算机病毒或黑客攻击、网络提供商提供的服务差异化或出现意外状况、用户所在位置以及其他任何技术、互联网络、通信线路等原因出现异常或其他不能满足用户要求的风险。对非中国知链造成的以上风险，可信大数据不承担任何责任。<br>
            </p>
            <p>
              <strong>三、知链电子书合约挂卖使用规范</strong>
            </p>
            <p>
              3.1  您必须合法地使用知链的各项服务，保证在使用知链的各项服务时所作陈述、记录等内容均真实、合法，您应当对通过您的帐户所实施的一切行为负责。<br>

3.2  您不得利用知链的各项服务及产品从事非法活动，包括且不限于利用本站传播、存取非法、违反公序良俗的内容，不可采用网络、电脑技术等各种手段，制造虚假证据，并利用知链存证，以获取个人利益、骗取保全证书。<br>

3.3  经发现或接到举报，或有合理理由怀疑您的帐户存在违法情况及其他违反本协议的行为时，知链有权对你的帐户采取限制措施，并限期要求您向知链公开可疑内容，限期进行整改，直至删除您的资料、应公安机关、司法机关的要求公开（公示）您的信息及违法内容、注销您的帐户。知链有权对您是否存在上述违法情况、违反本协议的行为单方作出认定，采取上述各项措施，无须征得您的同意或提前通知予您。<br>

3.4  对于您的行为（包括非法的或正常使用知链服务的行为）对任意第三方造成损害的，您均应当以自己的名义独立承担所有的法律责任，并应确保免于对知链造成损失或增加费用。<br>

3.5  一旦发生意外，非知链原因，收取的费用不予退还。在开始使用知链服务后，收费即开始。收费与使用功能服务的效果无关，您不得以效果不好达不到目的等理由，向知链提出退费要求。<br>

3.6  知链提供的各项服务均为自助型服务，请勿向客服人员提出参与的要求。<br>
            </p>
        </div>
     </div>
      <div class="btnWrap"> 
            <div>
                <el-checkbox v-model="allow">我同意</el-checkbox>
                  <router-link :to="{path:'/Contract',query: {}}"> 
                          《知链电子书挂卖合约协议》
                      </router-link>
            </div>
            <div>
                 <el-button
                type="primary"
                @click="onSubmit"
              >立即支付，获取授权</el-button>
            </div>
        </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'AgreementDialog',
  data () {
    return {
      allow:false,
      // 弹框是否显示
      visible: false,
    }
  },
  created () {
    this.visible = true
    this.init();
  },
  methods: {
    init () {
      console.log(this.visible)
    },
    // 关闭弹框时触发的事件
    onClose () {
      this.$emit("close");
    },
    onSubmit(){
      try {
        if(this.allow == false){
           this.$message.error("请了解并同意协议")
          return
        }
      } catch (error) {
        // 有错误,定位到错误
        // eslint-disable-next-line
        console.error(error)
        return
      }

      this.visible=false
        
    },

  }
}
</script>

<style lang='scss' scoped>
    .agreement{
        max-height:530px;
        overflow-y: auto;
        .topTittle{
          font-size: 24px;
          color:rgba(0, 18, 22,.85);
          margin-bottom: 8px;
          text-align: center;
          font-weight: bold;
        }
        .time{
           color:rgba(0, 18, 22,.65);
             text-align: center;
        }
        .desc{
          p{
            margin-top:16px;
          }
        }
    }
      .btnWrap{
          border-top:solid 1px #eee;
          padding:16px 8px 0;
          margin-top:16px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
</style>
